const Preloader = {
  selectors: '[data-preloader="load"]',
  init: function () {
    const $preloader = $(this.selectors);

    if ($preloader.length > 0) {
      setTimeout(function () {
        $('body').addClass('loaded');

        $preloader.addClass('loaded');
      }, $preloader.attr('data-delay') ?? 0);
    }
  }
};

export { Preloader };
export default Preloader;